import styled from "styled-components";
import { size, content as asideContent, convertContentToArray } from "../utils/helpers";
import publicIdentity from "../assets/public-identity.webp";
import publicClouds from "../assets/public-clouds.webp";
import publicImage from "../assets/fly_robot.png";
import publicLogo from "../assets/public-logo.png";
import lefttopimg from "../assets/pricing1.jpg";
import righttopimg from "../assets/pricing4.jpg";
import right2img from "../assets/pricing2.jpg";
import right3img from "../assets/pricing3.jpg";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import StartGrowingList from "../features/StartGrowingContent";
import { lazy, useMemo, useState, useEffect } from "react";
import { getContent } from "../hooks/useGetContent";
import FAQsection from "../layout/sales/PricingFAQ";
import CommenterBanner from "../assets/commenter_banner.png";
import checkIcon from "../assets/modal-check-icon.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import StarRating from "../components/StarRating";
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import "swiper/css/navigation";
import 'swiper/css/pagination';
import { Helmet } from 'react-helmet';

const FeedbackCard = ({
  image,
  title,
  subtitle,
  heading,
  rating,
  total,
  description,
}) => {
  return (
      <CardWrapper>
          <RatingHeader>
              <StarRating activeCount={rating} />
          </RatingHeader>
          {heading && (
              <CardHeading>{heading}</CardHeading>
          )}
          <CardDescription>{description}</CardDescription>
          <CardFooter>
              {image && (
                  <CardImage src={image} alt="" />
              )}
              <div>
                  <CardTitle>{title}</CardTitle>
                  <CardSubTitle>{subtitle}</CardSubTitle>
              </div>
          </CardFooter>
      </CardWrapper>
  );
}

const PublicPages = ({ children }) => {
  const location = useLocation();
  const isRegister = location.pathname === "/register";
  const [content, setContent] = useState({});
  const [faq, setFAQ] = useState({});
  const [feedbacks, setFeedbacks] = useState([]);
  const [slidesPerView, setSlidePerView] = useState(1);

  useEffect(() => {
    const handleResize = () => {
        setSlidePerView(1);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
        window.removeEventListener('resize', handleResize);
    }
}, []);

  useMemo(() => {
    getContent().then((response) => {
      setContent(response["pricing"]);
    });

    getContent().then((response) => {
      setFAQ(response["pricingFAQ"]);
    });
  }, []);

  useMemo(() => {
    getContent().then(response => {
        let feedbackData = response.homepage.fifthSectionContent?.content;
        if (feedbackData && feedbackData.length > 0) {
            const updatedContent = convertContentToArray(
                ['embedded-asset-block', 'heading-1', 'heading-2', 'heading-3', 'heading-4', 'paragraph'],
                ['image', 'title', 'subtitle', 'heading', 'rate', 'description'],
                feedbackData);
            setFeedbacks(updatedContent.filter(item => item.title !== 'Jaxon Gill'));
        }
    })

}, []);

  return (
    <>
      {/* <Helmet>
        <meta name="description" content="Sign up to save time on your LinkedIn comments. Let our AI improve your LinkedIn engagement today." />
      </Helmet> */}
	  
      <Container className="public-pages">
        <ContentSection className="content-section">
          <ContentImage src={publicLogo} className="content-image" />
          {children}
          <Outlet />
        </ContentSection>
        <Aside className="aside aside2">
          {/* {
                        isRegister ? '' : <AsideClouds src={publicClouds} alt="Commenter.ai clouds" />
                    }
                    <AsideContent className="aside-content">
                        {
                            isRegister ?
                                <>
                                    <AsideHeader className="aside-header">{asideContent.PublicPages.Aside.Title}</AsideHeader>
                                    <StartGrowingList content={content} />
                                </> :
                                <AsideIdentityImage src={publicIdentity} />
                        }
                    </AsideContent> */}
          <PlanWrpper>
            {/* <PlanboxOne>
              {/* <FreeplanPart>
                <h2>STARTER PLAN</h2>
              </FreeplanPart> */}
              {/* <ContentList>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>300 comments/month</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>1 emotion</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>TextTune™ - short comments</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>GenuYou™ - personalized comments</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Multi-language support</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Comment grammar check</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Standard support</Text>
                </ContentItem>
                <ContentItem>
                  <CheckIcon src={checkIcon} />
                  <Text>Commenter academy</Text>
                </ContentItem>
                <MonthPlan>
                  {" "}
                  <span></span>Just $12/month
                </MonthPlan>
              </ContentList> 
            </PlanboxOne> */}
            <PlanboxOne2>
              <Innerboxlefttop>
                <BoxleftImage src={lefttopimg} className="" />
              </Innerboxlefttop>
              <Innerboxrighttop className="toprightimages">
                <BoxleftImage2 src={righttopimg} className="" />
                <BoxleftImage2 src={right2img} className="" />
                <BoxleftImage2 src={right3img} className="" />
              </Innerboxrighttop>
            </PlanboxOne2>
            <Sliderboxdiv>
            <Section className="slider_right_sec">
                {feedbacks.length > 0 && (
                    <ContentWrapper>
                        <Swiper
                            navigation={true}
                            slidesPerView={slidesPerView}
                            spaceBetween={16}
                            pagination={{
                                dynamicBullets: true,
                            }}
                            modules={[Navigation, Pagination]}
                        >
                            {feedbacks.map((feedback, index) => (
                                <SwiperSlide key={`feedback-${index}`}>
                                    <FeedbackCard
                                        image={feedback.image}
                                        title={feedback.title}
                                        subtitle={feedback.subtitle}
                                        rating={+feedback.rate}
                                        heading={feedback.heading}
                                        total={feedbacks.length}
                                        description={feedback.description}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </ContentWrapper>
                )}
            </Section>
            </Sliderboxdiv>
            <Borderleft>
            </Borderleft>

          </PlanWrpper>
        </Aside>
      </Container>
      <FAQsection
        content={content.faq}
        faq={faq}
        staticImages={content?.staticImages}
      />
    </>
  );
};



const Borderleft = styled.section`
border-bottom: 1px solid rgb(255 255 255);
width: calc(100% + 40%);
padding-top: 60px;
@media only screen and (max-width: 1023px) {
  padding: 25px 0px 0;
}
`;


const Section = styled.section`
  position: relative;
  // margin: 20px 1rem 0;
  text-align: center;
  width: 100%;
`;

const ContentWrapper = styled.div`
    max-width: 100%;
    // padding: 0 15px;
    box-sizing: border-box;
    ${'' /* padding-left: 20px;
    padding-right: 20px; */}
    margin: 0 auto 15px auto;
    @media only screen and (min-width: ${size.minWidthDesktop}) {
        // max-width: 680px;
        padding: 0px;
    }
    @media only screen and (min-width: ${size.minWidthTablet}) {
        // max-width: 780px;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 100%;
      padding: 0px 0px;
    }
`;

const CardWrapper = styled.div`
  
    height: 450px;
    display: flex;
    flex-flow: column;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
    padding: 30px;
    margin: 10px auto;
    min-height: 240px;
    height:100%;
    max-width: 75%;
    @media only screen and (min-width: ${size.minWidthTablet}) {
   
    // height: 240px;
    }
    @media only screen and (max-width: 1023px) {
      max-width: 100%;
    }
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
    @media only screen and (max-width: 1400px) {
      min-height: 333px;
    height:100%;
    }
    @media only screen and (max-width: 1399px) {
      // max-width: 470px;
      min-height: 313px;
      height:100%;
      padding:20px;
    }
    @media only screen and (max-width: 1299px) {
      // max-width: 420px;
    }
    @media only screen and (max-width: 1199px) {
      // max-width: 350px;
    }
    @media only screen and (max-width: 1023px) {
      max-width: 100%;
      min-height: 420px;
      height:100%;
      padding: 30px;
    }
`;

const CardDescription = styled.p`
  flex-grow: 1;
  font-size: 16px;
  text-align: start;
  margin: 0;
  margin-top: 13px;
  font-weight: 400;
  color:rgb(117, 117, 117);
`;

const CardImage = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 100%;
  object-fit: cover;
`;

const CardTitle = styled.h3`
  font-size: 20px;
  text-align: start;
  margin: 0;
  color: rgb(0, 0, 0);
  font-weight: 700;
`;

const CardSubTitle = styled.h4`
  font-size: 18px;
  font-weight: 400;
  text-align: start;
  margin: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  color:rgb(117, 117, 117);

`;

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 12px;
`;

const RatingHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CardHeading = styled.h5`
  font-size: 16px;
  font-weight: 700;
  text-align: start;
  margin: 0;
  margin-top: 11px;
  color: rgb(0, 0, 0);
  font-weight: 700;
`;

const MonthPlan = styled.div`
  color: #000;
  font-family: Space Grotesk;
  font-size: 19.5px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-top: 20px;
  text-align: center;
  > span {
    color: #000;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    text-decoration-line: line-through;
  }
`;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  @media only screen and (min-width: ${size.maxWidthTablet}) {
    flex-flow: row;
  }
`;

const Aside = styled.aside`
  position: relative;
  flex: 1;
  background-color: var(--main-bg);
  display: flex;
  align-items: center;
  flex-flow: column;
  color: var(--white);
  // animation: slide-right-public 1.25s;
  width: 100%;
  padding: 0px 30px 0 65px;
  
  @media only screen and (min-width: ${size.maxWidthTablet}) {
    width: 50%;
  }
  @media only screen and (max-width: 1230px) {
    padding: 0px 15px;
  }
`;
const AsideContent = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100%;
  padding:0 15px;
`;
const AsideImage = styled.img`
  margin-top: 20px;
  // animation: image-fade-up 1.25s;
  width: 130px;
  @media only screen and (min-width: ${size.maxWidthTablet}) {
    display: none;
    width: auto;
  }
`;

const AsideClouds = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 95%;
`;

const AsideIdentityImage = styled.img`
  width: 100%;

  @media only screen and (max-width: ${size.maxWidthTablet}) {
    margin-top: 7rem;
    margin-bottom: 3rem;
  }
`;

const AsideHeader = styled.h2`
  width: 100%;
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
  margin-top: 43px;
  margin-bottom: 0;
  // animation: image-fade-up-main 1.75s;
  @media only screen and (max-width: ${size.maxWidthTablet}) {
    margin-top: 16.5px;
    font-size: 20px;
    font-weight: 700;
    max-width: 360px;
  }
`;

const ContentSection = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100%;
  @media only screen and (min-width: ${size.maxWidthTablet}) {
    > img + div {
      width: 100%;
    }
    width: 50%;
  }
`;
const ContentImage = styled.img`
  // animation: image-fade-up-main 3.75s;
  margin-top: 26px;
  // display: none;
  padding-top: 1rem;
  @media only screen and (min-width: ${size.maxWidthTablet}) {
    display: block;
  }
`;
const BoxleftImage = styled.img`
width: 100%;
  // margin-top: 15px;
  -webkit-box-shadow: -1px -2px 17px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px -2px 17px -8px rgba(0,0,0,0.75);
  box-shadow: -1px -2px 17px -8px rgba(0,0,0,0.75);
  border-radius: 7px;
  @media only screen and (min-width: ${size.maxWidthTablet}) {
    display: block;
    object-fit:fill;
  }
`;
const BoxleftImage2 = styled.img`
width: 100%;
  // margin-top: 15px;
  -webkit-box-shadow: -1px -2px 17px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px -2px 17px -8px rgba(0,0,0,0.75);
  box-shadow: -1px -2px 17px -8px rgba(0,0,0,0.75);
  border-radius: 7px;
  @media only screen and (min-width: ${size.maxWidthTablet}) {
    display: block;
    object-fit:fill;
  }
`;

const Planbox = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 0px 15px;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
    padding: 0px;
  }
`;
const PlanboxOne = styled.div`
  // // max-width: 389px;
  // width: 100%;
  // height: max-content;
  // border-radius: 45px;
  // border: 8px solid #ebebeb;
  // background: var(--w, #fff);
  // box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.16);
  // @media only screen and (max-width: 991px) {
  //   max-width: 89%;
  //   margin: auto;
  }
`;
const PlanboxOne2 = styled.div`
  width: 100%;
  display: flex;
  gap: 25px;
  margin-top:10px;
  height: max-content;
  @media only screen and (max-width: 1023px) {
    display: block;
    // max-width: 89%;
    margin: auto;
  }
`;

const Innerboxlefttop = styled.div`
  max-width: 61%;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    margin: auto;
  }
  @media only screen and (max-width: 1024px) {
    
    margin: 50px auto 20px;
  }
  > img{
    height: 100%;
  }
`;
const Innerboxrighttop = styled.div`
  max-width: 39%;
  width: 100%;
  min-height: 392px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:20px;
    
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    margin: auto;
  }
  > img{
    height: 100%;
  }
`;
const Sliderboxdiv = styled.div`
  width: 100%;
  margin-top: 25px;
  height: max-content;
  // background: red;
  // max-width: 636px !important;
  @media only screen and (max-width: 1399px) {
    // max-width: 446px !important;
  }
  @media only screen and (max-width: 1199px) {
    // max-width: 400px !important;
  }
  @media only screen and (max-width: 1099px) {
    // max-width: 350px !important;
  }
  @media only screen and (max-width: 1024px) {
    // max-width: 90% !important;
    margin: auto;
    margin-top: 25px;
  }
`;
const FreeplanPart = styled.div`
  border-radius: 32px 32px 0px 0px;
  background: radial-gradient(
    142.67% 120.19% at 91.93% 3.53%,
    #d0e5ff 0%,
    #bfd3ec 0.01%,
    #dedede 48.92%,
    #dcecff 91.67%
  );
  & > h2 {
    color: #4b4b4b;
    font-family: Space Grotesk;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    /* padding: 41px 105px 55px 103px; */
    margin: 0px;
    text-align: center;
    min-height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const RedButtonclick = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: center;
`;
const NeeDed = styled.div`
  color: #333;
  text-align: center;
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 134.1%;
  letter-spacing: -0.8px;
  text-transform: capitalize;
  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const CheckIcon = styled.img`
  width: 9px;
  height: 9px;
`;
const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 40px 20px;
`;
const ContentItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
const Text = styled.div`
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #12100b;
`;
const PlanWrpper = styled.div`
  display: flex;
  // align-item: center;
  // justify-content: center;
  // border-bottom: 1px solid white;
  width: 100%;
  padding: 60px 0px 0;
 
`;

export default PublicPages;
