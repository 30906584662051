import { useState, lazy, Suspense, useMemo } from "react";
import styled from "styled-components";
import { getContent } from "../../hooks/useGetContent";
import { size } from "../../utils/helpers";
import commnterimg from "../../assets/commnterimg.png";
import work1 from "../../assets/work1.png";
import work2 from "../../assets/work2.png";
import work3 from "../../assets/work3.png";
import mission3 from "../../assets/mission3.png";
import vertical_slide1 from "../../assets/vertical_slide1.png";
import shapebtn1 from "../../assets/shapebtn1.png";
import shapebtn2 from "../../assets/shapebtn2.png";
import shapebtn3 from "../../assets/shapebtn3.png";
import shapebtn4 from "../../assets/shapebtn4.png";
import shapebtn5 from "../../assets/shapebtn5.png";
import experts from "../../assets/experts.png";
import slide1 from "../../assets/slide1.png";
import Filled from "../../assets/Filled.png";
import partnerimg from "../../assets/partnerimg.png";
import tick2 from "../../assets/tick2.png";
import cercaleimg from "../../assets/cercaleimg.png";
import zigzagicon from "../../assets/zigzagicon.png";
import commnterfoot from "../../assets/commnterfoot.png";
import client1 from "../../assets/client1.png";
import client2 from "../../assets/client2.png";
import client3 from "../../assets/client3.png";
import SliderImg1 from "../../assets/1-1new.jpeg";
import SliderImg1_2 from "../../assets/1-2new.jpeg";
import { Helmet } from 'react-helmet';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { NextArrow2 } from "../../components/NextArrow2";
// import { PrevArrow2 } from "../../components/PrevArrow2";

import lefttopimg from "../../assets/pricing1.jpg";
import BackArrow from "../../assets/uparr.png";
import NextArrows from "../../assets/down2.png";

import BackArrow2 from "../../assets/left1.png";
import NextArrows2 from "../../assets/right1.png";
import { Link } from "react-scroll";
// Custom previous and next arrow components with images
const PrevArrow = (props) => <img {...props} src={BackArrow} alt="Previous" />;
const NextArrow = (props) => <img {...props} src={NextArrows} alt="Next" />;

const PrevArrow2 = (props) => (
  <img {...props} src={BackArrow2} alt="Previous" />
);
const NextArrow2 = (props) => <img {...props} src={NextArrows2} alt="Next" />;

const Commenterhome = () => {
  const settings1 = {
    dots: false, // Disable dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: false,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />, // Custom previous arrow component with image
    nextArrow: <NextArrow />, // Custom next arrow component with image
  };

  const settings2 = {
    dots: false, // Disable dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    verticalSwiping: false,
    autoplay: false,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow2 />, // Custom previous arrow component with image
    nextArrow: <NextArrow2 />, // Custom next arrow component with image
  };

  return (
    <>
        {/* <Helmet>
       <meta name="description" content="Partner with Commenter.ai to help others with their LinkedIn comments. Work with us to bring our AI LinkedIn commenting tool to the masses." />
      </Helmet> */}
      
      <Section>
        <Divcontainer>
          <Divinner1>
            <Title>
              Become a <span>Commenter AI</span> partner
            </Title>
            <p className="banderpar">
              Partner with the leading AI comment generator for LinkedIn and
              earn passive income by helping people scale their engagement
              results
            </p>
            <Divbgshape>
              <SvgImge></SvgImge>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSdlL97RtwxmOOKGW0M8XzhHT36tDMoEQiD-GC--6ApKDLwBCQ/viewform" target="_blank"  className="apply_button becomeapplybtn">
                Apply Now
                <Imageshape
                  src={shapebtn1} // Path to your image from the public folder
                  alt="img"
                  width={30} // Set the width of the image
                  height={30} // Set the height of the image
                  className="shapebtn1"
                />
                <Imageshape
                  src={shapebtn2} // Path to your image from the public folder
                  alt="img"
                  width={30} // Set the width of the image
                  height={30} // Set the height of the image
                  className="shapebtn2"
                />
                <Imageshape
                  src={shapebtn3} // Path to your image from the public folder
                  alt="img"
                  width={78} // Set the width of the image
                  height={28} // Set the height of the image
                  className="shapebtn3"
                />
              </a>
            </Divbgshape>
          </Divinner1>
          <Divinner2>
            <Imageshape
              src={commnterimg} // Path to your image from the public folder
              alt="img"
              width={464} // Set the width of the image
              height={500} // Set the height of the image
              className="banner_img1"
            />
          </Divinner2>
        </Divcontainer>
      </Section>

      <Section2>
        <Title2>How it works</Title2>
        <Divcontainer2>
          <Box3 className="imagebefore">
            <Imageshape
              src={shapebtn4} // Path to your image from the public folder
              alt="img"
              width={178} // Set the width of the image
              height={37} // Set the height of the image
              className="shapebtn4"
            />
            <Imageshape2
              src={work1} // Path to your image from the public folder
              alt="img"
              width={270} // Set the width of the image
              height={234} // Set the height of the image
            />
            <div>
              <h3>Apply</h3>
              <p>Just fill out the simple form and we will get back to you.</p>
            </div>
          </Box3>
          <Box3>
            <Imageshape
              src={shapebtn5} // Path to your image from the public folder
              alt="img"
              width={178} // Set the width of the image
              height={37} // Set the height of the image
              className="shapebtn5"
            />
            <Imageshape2
              src={work2} // Path to your image from the public folder
              alt="img"
              width={270} // Set the width of the image
              height={234} // Set the height of the image
            />
            <div>
              <h3>Promote</h3>
              <p>Get your unique link and share it with your world.</p>
            </div>
          </Box3>
          <Box3>
            <Imageshape2
              src={work3} // Path to your image from the public folder
              alt="img"
              width={270} // Set the width of the image
              height={234} // Set the height of the image
            />
            <div>
              <h3>Earn</h3>
              <p>
                Receive your commissions every month to your preferred payment
                method
              </p>
            </div>
          </Box3>
        </Divcontainer2>
      </Section2>

      <Section3>
        <Divcontainer3>
          <MissionBox1 className="imagebefore">
            <Imageshape
              src={mission3} // Path to your image from the public folder
              alt="img"
              width={600} // Set the width of the image
              height={595} // Set the height of the image
              className="join_mission"
            />
          </MissionBox1>
          <MissionBox2>
            <div>
              <Title3>
                Join our <span>mission</span>
              </Title3>
              <p className="par3">
                We aim to help 100,000 people to achieve more with their
                comments while saving time. Refer Commenter AI and earn 20%
                commission (or more). Apply today and start earning growing
                commissions (and even more than that).
              </p>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSdlL97RtwxmOOKGW0M8XzhHT36tDMoEQiD-GC--6ApKDLwBCQ/viewform" target="_blank" className="apply_button apply_joinbtn">Apply Now</a>
            </div>
          </MissionBox2>
        </Divcontainer3>
      </Section3>

      <Section4>
        <Divcontainer4>
        <MissionBox4>
            <div className="cusresultdiv cusresul2">
              <Title4>
                Commenter AI's <span>Customer results</span>
              </Title4>
              <p>
              These are some of the results of our customers.
              </p>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSdlL97RtwxmOOKGW0M8XzhHT36tDMoEQiD-GC--6ApKDLwBCQ/viewform" target="_blank"  className="apply_button aftershowondesk ">Apply Now</a>
            </div>
          </MissionBox4>
          <MissionBox9 className="imagebefore">
            <Slider
              {...settings1}
              className="result_slider_verti1 sldercommenter"
            >
              {/* <div className="wrapperimge">
                  <Imageshape
                    src={vertical_slide1} // Path to your image from the public folder
                    alt="img"
                    width={559} // Set the width of the image
                    height={417} // Set the height of the image
                    className="join_mission"
                  />
                </div>
                <div className="wrapperimge">
                  <Imageshape
                    src={vertical_slide1} // Path to your image from the public folder
                    alt="img"
                    width={559} // Set the width of the image
                    height={417} // Set the height of the image
                    className="join_mission"
                  />
                </div> */}

              <SliderWrap>
                <SliderPannelLeft>
                  <h2>
                    <p className="aftertext">Before</p>
                    <p className="beforetext">(79 profile views)</p>
                  </h2>
                  <div>
                    <SliderImgWrapper src={SliderImg1} alt="sliderone" />
                  </div>
                </SliderPannelLeft>
                <SliderPannelRight>
                  <h2 className="afterh2">
                    <p className="aftertext">After</p>
                    <p className="beforetext">(476 profile views)</p>
                  </h2>
                  <div>
                    <SliderImgWrapper src={SliderImg1} alt="sliderone" />
                  </div>
                </SliderPannelRight>
              </SliderWrap>
              <SliderWrap>
                <SliderPannelLeft>
                  <h2>
                    <p className="aftertext">Before</p>
                    <p className="beforetext">(79 profile views)</p>
                  </h2>
                  <div>
                    <SliderImgWrapper src={SliderImg1} alt="sliderone" />
                  </div>
                </SliderPannelLeft>
                <SliderPannelRight>
                  <h2 className="afterh2">
                    <p className="aftertext">After</p>
                    <p className="beforetext">(476 profile views)</p>
                  </h2>
                  <div>
                    <SliderImgWrapper src={SliderImg1} alt="sliderone" />
                  </div>
                </SliderPannelRight>
              </SliderWrap>
            </Slider>
          </MissionBox9>
          <MissionBox4 className="aftershowmb">
            <div className="cusresultdiv">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSdlL97RtwxmOOKGW0M8XzhHT36tDMoEQiD-GC--6ApKDLwBCQ/viewform" target="_blank"  className="apply_button">Apply Now</a>
            </div>
          </MissionBox4>
        </Divcontainer4>
      </Section4>

      <Section5>
        <Title5>
          Approved by <span>top experts</span>
        </Title5>
        <Divcontainer5>
          <div className="">
            <Slider {...settings2} className="result_slider_verti2">
              <div className="slides_slick slides_slick2 ">
                <div className="missiondiv">
                  <Imageshape
                    src={client1} // Path to your image from the public folder
                    alt="img"
                    width={488} // Set the width of the image
                    height={572} // Set the height of the image
                    className="join_mission2 mission2"
                  />
                </div>

                <div className="startwarpdiv">
                  <Imageshape
                    src={slide1} // Path to your image from the public folder
                    alt="img"
                    width={20} // Set the width of the image
                    height={20} // Set the height of the image
                    className=""
                  />
                  <div className="star_slide">
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                  </div>
                  <p>
                  I know lots of folks who find commenting scary. They find it super hard to leave "good" comments. They cannot formulate thoughts in a split second. Commenter AI can help with that initial idea. Then, it's yours to bring it home and make the commenting game easier and better.
                  </p>

                  <p className="slidename">Jasmin Alic</p>
                  {/* <p className="slidewho">Someone</p> */}
                </div>
              </div>
              <div className="slides_slick slides_slick2 ">
                <div className="missiondiv">
                  <Imageshape
                    src={client2} // Path to your image from the public folder
                    alt="img"
                    width={488} // Set the width of the image
                    height={572} // Set the height of the image
                    className="join_mission2 mission2"
                  />
                </div>

                <div className="startwarpdiv">
                  <Imageshape
                    src={slide1} // Path to your image from the public folder
                    alt="img"
                    width={20} // Set the width of the image
                    height={20} // Set the height of the image
                    className=""
                  />
                  <div className="star_slide">
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                  </div>
                  <p>
                  Commenter AI can help you articulate the idea in a way you might have thought was impossible.
                  </p>

                  <p className="slidename">Tasleem Ahmad Fateh</p>
                  {/* <p className="slidewho">Someone</p> */}
                </div>
              </div>
              <div className="slides_slick slides_slick2 ">
                <div className="missiondiv">
                  <Imageshape
                    src={client3} // Path to your image from the public folder
                    alt="img"
                    width={488} // Set the width of the image
                    height={572} // Set the height of the image
                    className="join_mission2 mission2"
                  />
                </div>

                <div className="startwarpdiv">
                  <Imageshape
                    src={slide1} // Path to your image from the public folder
                    alt="img"
                    width={20} // Set the width of the image
                    height={20} // Set the height of the image
                    className=""
                  />
                  <div className="star_slide">
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                    <span>
                      <Imageshape
                        src={Filled} // Path to your image from the public folder
                        alt="img"
                        width={20} // Set the width of the image
                        height={20} // Set the height of the image
                        className=""
                      />
                    </span>
                  </div>
                  <p>
                  Commenter AI helps me to save hours of my time. Not only that, but we have scaled our conversations to new highs by adding the tool to our daily routine. I recommend it to all my clients.
                  </p>

                  <p className="slidename">Dean Seddon</p>
                  {/* <p className="slidewho">Someone</p> */}
                </div>
              </div>
            </Slider>
          </div>
        </Divcontainer5>
      </Section5>

      <Section6>
        <Divcontainer6>
          <MissionBox7>
            <div className="cusresultdiv rusltparter">
              <Title6>
                Why partner with <br></br>
                <span>Commenter AI</span>
              </Title6>
              <Imageshape
                  src={partnerimg} // Path to your image from the public folder
                  alt="img"
                  width={484} // Set the width of the image
                  height={470} // Set the height of the image
                  className="join_mission join_mission_showonmb"
                />
              <div className="tickouterdiv">
                <div className="tickdive">
                  <Imageshape
                    src={tick2} // Path to your image from the public folder
                    alt="img"
                    width={25} // Set the width of the image
                    height={25} // Set the height of the image
                    className=""
                  />
                  <p>20% + Commission</p>
                </div>
                <div className="tickdive">
                  <Imageshape
                    src={tick2} // Path to your image from the public folder
                    alt="img"
                    width={25} // Set the width of the image
                    height={25} // Set the height of the image
                    className=""
                  />
                  <p>Opportunity to earn cash bonuses</p>
                </div>
                <div className="tickdive">
                  <Imageshape
                    src={tick2} // Path to your image from the public folder
                    alt="img"
                    width={25} // Set the width of the image
                    height={25} // Set the height of the image
                    className=""
                  />
                  <p>Opportunity to earn equity in the company</p>
                </div>
                <div className="tickdive">
                  <Imageshape
                    src={tick2} // Path to your image from the public folder
                    alt="img"
                    width={25} // Set the width of the image
                    height={25} // Set the height of the image
                    className=""
                  />
                  <p>Personal support</p>
                </div>
                <div className="tickdive">
                  <Imageshape
                    src={tick2} // Path to your image from the public folder
                    alt="img"
                    width={25} // Set the width of the image
                    height={25} // Set the height of the image
                    className=""
                  />
                  <p>90 Days cookies</p>
                </div>
              </div>
            </div>
          </MissionBox7>
          <MissionBox5 className="imagebefore partnerwrap2">
            <div className="">
              <div className="wrapperimge">
                <Imageshape
                  src={partnerimg} // Path to your image from the public folder
                  alt="img"
                  width={484} // Set the width of the image
                  height={470} // Set the height of the image
                  className="join_mission join_mission_showondesk"
                />
              </div>
            </div>
          </MissionBox5>
        </Divcontainer6>
      </Section6>

      <Sectionfooter>
        <Footcon>
          <div className="footcontent">
            <h2 className="footheading">Join us today</h2>
            <p>
              Be a part of the Commenter AI Affiliate Program to earn recurring
              income.
            </p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdlL97RtwxmOOKGW0M8XzhHT36tDMoEQiD-GC--6ApKDLwBCQ/viewform" target="_blank" 
              className="apply_button"
            >
              Apply Now
            </a>

            <Imageshape
              src={zigzagicon} // Path to your image from the public folder
              alt="img"
              width={62} // Set the width of the image
              height={72} // Set the height of the image
              className="imgcerclefoot2"
            />
            <Imageshape
              src={cercaleimg} // Path to your image from the public folder
              alt="img"
              width={62} // Set the width of the image
              height={72} // Set the height of the image
              className="imgcerclefoot"
            />
          </div>

          <div className="commnter_foot">
            <div className="foot33">
              <ul>
                <li>
                  <a href="/terms-conditions" target="_blank"  >Terms of Services</a>
                </li>
                <li>
                  <a href="/privacy" target="_blank"  >Privacy Policy</a>
                </li>
                <li>
                  <a href="/contact-us" target="_blank" >Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="foot332">
              <a href="/" target="_blank" >
                <Imageshape
                  src={commnterfoot} // Path to your image from the public folder
                  alt="img"
                  width={155} // Set the width of the image
                  height={40} // Set the height of the image
                  className="commnterfootimg "
                />
              </a>
            </div>

            <div className="foot333">
              <p className="commntercopyright">
                © Commenter.ai, All rights reserved.
              </p>
            </div>
          </div>
        </Footcon>
      </Sectionfooter>
    </>
  );
};

const SliderImgWrapper = styled.img`
  width: 100%;
  object-fit: contain;
`;
const SliderWrap = styled.div`
  display: flex !important;
  gap: 35px;

  flex-direction: column;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;
const SliderPannelLeft = styled.div`
  display: flex;
  > h2 {
    // max-width: 574px;
    // min-height: 55px;
    border-radius: 4px 4px 0px 0px;
    margin: 0px;
    // display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ff3131;
    // background: #FF3131;
    padding: 0 15px;
    font-family: Space Grotesk;
    font-size: 47px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 0px;
    line-height: 35.43px;
    text-align: center;
    @media only screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 28px;
      margin-top: 0px;
    }
    > img {
      width: 100%;
    }
  }
`;
const SliderPannelRight = styled.div`
  display: flex;

  > h2 {
    // max-width: 574px;
    // min-height: 55px;
    border-radius: 4px 4px 0px 0px;
    margin: 0px;
    // display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0px;
    color: #0050b2;
    // background: #0050B2;
    padding: 0 15px;
    font-family: Space Grotesk;
    font-size: 47px;
    font-weight: 700;
    line-height: 35.43px;
    text-align: center;
    @media only screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 28px;
    }

    > img {
      width: 100%;
    }
  }
`;

const Sectionfooter = styled.div`
  padding: 60px 15px;
  background: linear-gradient(180deg, #41bcff -23.62%, #0050b2 139.31%);

  @media only screen and (max-width: 1299px) {
    padding: 20px 15px 40px 15px;
  }
  //     @media only screen and (max-width: 1024) {
  //   height: 1030px;
  // }
  @media only screen and (max-width: 991px) {
    // height: 1030px;
  }
`;

const Footcon = styled.div`
  max-width: 1281px;
  margin: auto;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    margin: auto;
    flex-wrap: wrap;
  }
`;

const Section6 = styled.div`
  padding: 60px 15px;

  @media only screen and (max-width: 1299px) {
    padding: 0px 15px 40px 15px;
  }
  //     @media only screen and (max-width: 1024) {
  //   height: 1030px;
  // }
  @media only screen and (max-width: 991px) {
    padding: 0px 15px 0px 15px;
    // height: 1030px;
  }
`;

const Section5 = styled.div`
  padding: 60px 15px;

  @media only screen and (max-width: 1299px) {
    padding: 0px 15px 40px 15px;
  }
  @media only screen and (max-width: 991px) {
    padding: 0px 15px 40px 15px;
  }
`;

const Divcontainer5 = styled.div`
  max-width: 1281px;
  margin: auto;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    margin: auto;
    flex-wrap: wrap;
  }
`;

const MissionBox6 = styled.div`
  // max-width: 439px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    margin: auto;
  }

  div > p {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #1d1b1b;
  }
`;

const Title5 = styled.div`
  font-family: Montserrat;
  font-size: 42px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #0c111f;
   margin-bottom: 30px;
  > span {
    color: #41bcff;
  }

  @media only screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 30px;
   
  }
`;

const Divbgshape = styled.div`
  position: relative;
`;
const SvgImge = styled.div``;

const Section4 = styled.div`
  padding: 60px 15px;

  @media only screen and (max-width: 1299px) {
    padding: 0px 15px 40px 15px;
  }
  @media only screen and (max-width: 991px) {
    padding: 0px 15px 40px 15px;
  }
`;

const Divcontainer4 = styled.div`
  max-width: 1281px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    margin: auto;
    flex-wrap: wrap;
  }
`;
const Divcontainer6 = styled.div`
  max-width: 1071px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    margin: unset;
    flex-wrap: wrap;
  }
`;
const MissionBox5 = styled.div`
  max-width: 577px;

  @media only screen and (max-width: 1024px) {
    max-width: 90%;
    margin: auto;
  }
`;

const MissionBox9 = styled.div`
  max-width: 654px;

  @media only screen and (max-width: 1024px) {
    max-width: 90%;
    margin: auto;
  }
`;

const MissionBox4 = styled.div`
  max-width: 439px;

  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    margin: auto;
  }

  div > p {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #1d1b1b;

    @media only screen and (max-width: 1024px) {
      text-align: center;
    }
  }
`;

const MissionBox7 = styled.div`
  // max-width: 439px;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin: auto;
  }

  div > p {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #1d1b1b;
    margin: 9px 0px;

    @media only screen and (max-width: 1024px) {
      text-align: center;
    }
  }
`;

const Title4 = styled.div`
  font-family: Montserrat;
  font-size: 42px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #0c111f;
  > span {
    color: #41bcff;
  }

  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 30px;
  }
`;

const Title6 = styled.div`
  font-family: Montserrat;
  font-size: 42px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #0c111f;
  > span {
    color: #41bcff;
  }

  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
  }
`;

const Section3 = styled.div`
  padding: 60px 15px;

  @media only screen and (max-width: 1299px) {
    padding: 0px 15px 40px 15px;
  }
  @media only screen and (max-width: 991px) {
    padding: 0px 15px 40px 15px;
  }
`;

const Divcontainer3 = styled.div`
  max-width: 1281px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    margin: auto;
    flex-wrap: wrap;
  }
`;

const MissionBox1 = styled.div`
  max-width: 577px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    margin: auto;
  }
`;

const MissionBox2 = styled.div`
  max-width: 439px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    margin: auto;
  }

  div > p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
`;

const Title3 = styled.div`
  font-family: Montserrat;
  font-size: 42px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1px;
  text-align: left;
  color: #213657;
  > span {
    color: #41bcff;
  }

  @media only screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
  }
`;

const Section = styled.div`
  padding: 60px 15px 60px 15px;

  @media only screen and (max-width: 1299px) {
    padding: 0px 15px 40px 15px;
  }
  @media only screen and (max-width: 991px) {
    padding: 0px 15px 40px 15px;
  }
`;

const Section2 = styled.div`
  padding: 0px 15px 60px 15px;
`;
const Divcontainer = styled.div`
  max-width: 1281px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 991px) {
    flex-direction: column-reverse;
  }
`;

const Divcontainer2 = styled.div`
  max-width: 1281px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: auto;

  @media only screen and (max-width: 991px) {
    width: 100%;
    flex-direction: column;
  }
    @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    max-width: 236px;
  }
`;
const Divinner1 = styled.div`
  width: 61%;

  @media only screen and (max-width: 991px) {
    width: 100%;
    text-align: center;
  }

  > p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    margin-top: 20px;

    @media only screen and (max-width: 1199px) {
      font-size: 16px;
      line-height: 26px;
      text-align: center;
    }
  }
`;
const Divinner2 = styled.div`
  width: 39%;
  justify-content: end;
  display: flex;

  @media only screen and (max-width: 991px) {
    width: 100%;
    justify-content: center;
  }
`;
const Box3 = styled.div`
    position: relative;
max-width: 305px;
  display: flex;
  flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

@media only screen and (max-width: 680px) {
            width: 100%;
            max-width: 100%;
        }
   
   
        > div h3 {
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: #213657;
                margin-bottom: -4px;

             @media only screen and (max-width: 768px) {
                font-size: 22px;
                line-height: 22px;
            
            }
        }

        >div p {
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;

            }

  }  

  > p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    margin-top: 20px;
  }
`;
const Title2 = styled.div`
  font-family: Montserrat;
  font-size: 42px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1px;
  text-align: center;
  color: #213657;
  margin-bottom: 50px;

  @media only screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 64px;
  font-weight: 700;
  line-height: 78px;
  letter-spacing: -2.5px;
  text-align: left;
  color: #213657;
  > span {
    color: #41bcff;
  }

  @media only screen and (max-width: 1199px) {
    font-size: 44px;
    line-height: 50px;
  }
  @media only screen and (max-width: 991px) {
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }
`;
const Imageshape = styled.img``;

const Imageshape2 = styled.img``;

const GroupingParent = styled.div`
  padding: 28px 15px 26px;
  margin-bottom: 72px;
  margin-top: 50px;
  background: #e3e3e3;
  @media only screen and (min-width: 767px) {
    display: none;
  }
  p {
    padding: 0 6px;
    max-width: 396px;
    height: 59px;
    margin: auto;
    margin-bottom: 12px;
    text-align: center;
    border-radius: 40px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 124.5%;
    color: white;
    background: #0050b2;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 480px) {
      font-size: 13px;
    }
  }
`;

export default Commenterhome;
